
import QRCode from "qrcode";
import html2canvas from "html2canvas";

export default {
    props: {
        poster: null,
        titleColor:{
            default:'white'
        },
        descColor:{
            default:'white'
        }
    },
    data() {
        return {
            show: true,
            url: '',
            startX: 0,   //鼠标按下的位置
            startY: 0,
            width: 0,  //屏幕宽度
            length: 0,  //图片长度
            leave: 0,   //移动距离
            imgIndex: 0,  //序号
            isEnd: false, //到尾
            qrcodeURL: '',  //二维码链接
            top: 0,
            color:'white',
        }
    },
    created() {
        let top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        this.top = top
        console.log(top)
        window.pageYOffset = 0;
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
        this.$toast.loading({
            message: '加载中...',
            forbidClick: true,
            duration: 10000
        });
        this.$nextTick(() => {
            //动态计算海报宽，宽：高 = 1：1.5
            this.width = window.innerWidth * 0.67 + 0.2
            let postersHeight = this.width * 1.5   //宽
            document.querySelector(".inside-posets").style.height = postersHeight + 'px'
            document.querySelector(".posets > img").style.height = postersHeight + 'px'
            // 图片长度
            this.length = this.poster.backUrl.length
            // 动态计算盒子长度
            this.$refs.imgList.style.width = (this.width * (this.length + 1)) + 'px'
            this.$refs.img.forEach(value => {
                value.style.width = this.width + 'px'
            })
            this.createQRcode()
        })
    },
    beforeDestroy() {
        window.pageYOffset = this.top;
        document.documentElement.scrollTop = this.top
        document.body.scrollTop = this.top

    },
    methods: {
        imgError(){
            this.$toast.clear()
        },
        createQRcode() {
            QRCode.toDataURL(this.poster.href, {
                version: 7,    //这个是版本号，
                errorCorrectionLevel: 'Q', 	//这个是容错率,(建议选较低)更高的级别可以识别
                                              //更模糊的二维码，但会降低二维码的容量
                width: 280,
                height: 280,   //设置二维码图片大小
                margin: 0,
            })
                .then(url => {
                    this.qrcodeURL = url
                    // let qrcode = document.querySelector("#qrcode img")
                    // qrcode.setAttribute("src",url)

                }).catch(err => {
                console.error(err)
            })

        },
        createaPosters() {
            this.$toast.clear()
            this.color = 'transparent'
            setTimeout(() => {
                new html2canvas(document.querySelector('.inside-posets'), {
                    allowTaint: false,
                    useCORS: true,
                    backgroundColor: null,
                    // scale:2,
                    dpi: 300
                }).then(canvas => {
                    this.show = false
                    this.url = canvas.toDataURL("image/png")
                });
            }, 300);
        },
        //左右滑动
        handMove(e) {
            if (this.poster.backUrl.length <= 1) {
                return false
            }
            // e.preventDefault()
            let moveX = e.changedTouches[0].pageX
            //获取移动距离
            this.leave = moveX - this.startX
            //开始滑动
            let leaveWidth = -this.imgIndex * this.width
            this.$refs.imgList.style.transform = "translateX(" + (this.leave + leaveWidth) + "px)"
            //当右滑，也就是从0到4的时候
            if ((this.leave > 0) && this.imgIndex == 0) {
                this.$refs.img[this.length - 1].style.transform = "translateX(" + this.length * -this.width + "px)"
                this.isEnd = true
            }
        },
        //鼠标按下
        handDown(e) {
            if (this.poster.backUrl.length <= 1) {
                return false
            }

            this.startX = e.changedTouches[0].pageX
            this.$refs.imgList.style.transition = ""
            if (this.isEnd) {
                this.imgIndex = this.length - 1
                this.$refs.imgList.style.transform = "translateX(" + -this.imgIndex * this.width + "px)"
                this.$refs.img[this.length - 1].style.transform = "translateX(0px)"
                this.isEnd = false
            }
            if (this.imgIndex == this.length) {
                this.imgIndex = 0
                this.$refs.imgList.style.transform = "translateX(" + -this.imgIndex * this.width + "px)"

            }

        },
        //鼠标放开
        handUp(e) {
            if (this.poster.backUrl.length <= 1) {
                return false
            }

            //获取放开距离，防止点击就轮播
            let handUpX = e.changedTouches[0].pageX
            this.$refs.imgList.style.transition = "all 0.4s"
            //判断，如果滑动距离小于一半，就返回原装
            if (Math.abs(this.leave) < this.width * 0.5) {
                this.$refs.imgList.style.transform = "translateX(" + -this.imgIndex * this.width + "px)"
                this.isEnd = false
            } else {
                //左滑
                if ((this.leave < 0) && (handUpX != this.startX)) {
                    this.imgIndex++
                    this.url = ''
                } else if (handUpX != this.startX) {
                    this.imgIndex--
                    this.url = ''
                }
                this.$refs.imgList.style.transform = "translateX(" + -this.imgIndex * this.width + "px)"

                this.$nextTick(() => {
                    setTimeout(() => {
                        this.$refs.imgList.style.transition = ""
                        new html2canvas(document.querySelector('.inside-posets'), {
                            allowTaint: false,
                            useCORS: true,
                            backgroundColor: null,
                            // scale:window.devicePixelRatio,
                            scale: 2
                        }).then(canvas => {
                            this.show = false
                            this.url = canvas.toDataURL("image/png")
                        });
                    }, 300);
                })
            }
        },
        downPosters(e) {
            e.preventDefault()
            e.stopPropagation();
            this.$emit("downPosters")
        },
        // 取消图片自动保存
        cancel(e) {
            e.stopPropagation();
            e.preventDefault()
        }
    },
}
