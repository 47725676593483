var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    ref: "box",
    staticClass: "posets"
  }, [_c('div', {
    staticClass: "inside-posets",
    style: {
      background: _vm.color
    }
  }, [_c('div', {
    staticStyle: {
      "width": "100%",
      "height": "100%",
      "position": "relative"
    },
    attrs: {
      "id": "posets-img"
    }
  }, [_c('div', {
    staticClass: "top"
  }, [_c('div', {
    ref: "imgList",
    staticClass: "top-img-list"
  }, [_vm._l(_vm.poster.backUrl, function (item, index) {
    return _c('img', {
      key: index,
      ref: "img",
      refInFor: true,
      attrs: {
        "src": item
      },
      on: {
        "touchstart": function ($event) {
          return _vm.cancel($event);
        },
        "load": function ($event) {
          return _vm.createaPosters();
        },
        "error": _vm.imgError
      }
    });
  }), _vm._l(_vm.poster.backUrl.slice(0, 1), function (item2) {
    return _c('img', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.poster.backUrl.length > 1,
        expression: "poster.backUrl.length > 1"
      }],
      key: item2,
      ref: "img",
      refInFor: true,
      attrs: {
        "src": item2
      },
      on: {
        "touchstart": function ($event) {
          return _vm.cancel($event);
        },
        "handDown": function ($event) {
          return _vm.cancel($event);
        }
      }
    });
  })], 2)]), _c('div', {
    staticClass: "bottom"
  }, [_c('div', {
    staticClass: "bottom-left"
  }, [_c('img', {
    attrs: {
      "src": _vm.poster.presonImage
    }
  }), _c('div', {
    staticClass: "info",
    staticStyle: {
      "width": "calc(100% - 50px )"
    }
  }, [_c('p', {
    style: {
      color: _vm.titleColor
    }
  }, [_vm._v(_vm._s(_vm.poster.presonName))]), _c('span', {
    style: {
      color: _vm.descColor
    }
  }, [_vm._v(_vm._s(_vm.poster.info))])])]), _c('div', {
    attrs: {
      "id": "qrcode"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.qrcodeURL
    }
  })])])]), _c('div', {
    staticClass: "save"
  }, [_c('p', [_vm._v("长按海报即可保存/分享")]), _c('div', {
    staticClass: "down-button",
    style: {
      "background-image": "url(" + _vm.poster.shut + ")"
    },
    on: {
      "touchend": function ($event) {
        return _vm.downPosters($event);
      }
    }
  })])]), _c('img', {
    attrs: {
      "src": _vm.url
    },
    on: {
      "touchmove": function ($event) {
        return _vm.handMove($event);
      },
      "touchstart": function ($event) {
        $event.stopPropagation();
        return _vm.handDown($event);
      },
      "touchend": function ($event) {
        return _vm.handUp($event);
      }
    }
  })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };